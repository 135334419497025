<div
  class="row"
  style="
    margin-bottom: auto;
    height: 100%;
    background-image: url(../assets/images/placeholder.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  "
  lazyLoad="../assets/images/back.jpg"
>
  <div class="col l12 s12">
    <nav
      class="transparent nav-center"
      style="box-shadow: none; margin-top: 20px"
    >
      <div class="nav-wrapper">
        <a href="#" data-target="mobile-demo" class="sidenav-trigger"
          ><i class="material-icons black-text">menu</i></a
        >
        <ul class="center-align hide-on-med-and-down">
          <li><a class="black-text" href="#app">App</a></li>
          <li><a class="black-text" href="#whatsapp">Whatsapp</a></li>
          <li><a class="black-text" href="#nosotrosl">Nosotros</a></li>
        </ul>
      </div>
    </nav>
    <ul
      class="sidenav"
      id="mobile-demo"
      style="
        margin-bottom: auto;
        height: 100%;
        background-image: url(../assets/images/placeholder.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      "
      lazyLoad="../assets/images/back.jpg"
    >
      <div class="user-view"></div>
      <li><a class="sidenav-close" href="#apps">App</a></li>
      <li><a class="sidenav-close" href="#whatsapps">Whatsapp</a></li>
      <li><a class="sidenav-close" href="#nosotross">Nosotros</a></li>
    </ul>
  </div>

  <!--// SMARTPHONE-->
  <div class="hide-on-med-and-up">
    <div
      class="col s12 right-align hide-on-med-and-up"
      style="margin-top: 20px"
    >
      <div class="col s12 center-align">
        <img
          class="responsive-img left-align"
          style="width: 90%; height: 90%"
          src="../assets/images/norte_remis_1.svg"
        />
      </div>
      <div class="col s12 center-align">
        <div id="apps" class="row center-align scrollspy hide-on-med-and-up">
          <div>
            <p style="font-weight: bold; font-size: 20px; margin-bottom: 20%">
              ¡Llamá ahora!
            </p>
          </div>
        </div>
        <h1
          class="grey-text text-darken-4"
          style="
            font-family: 'Lato', sans-serif;
            font-size: 40px;
            text-shadow: 15px 15px 15px #aaa;
          "
        >
          266-4245230
          <a
            style="margin-left: 2%"
            href="tel:+542664245230"
            class="btn-floating waves-effect waves-light btn-large hoverable z-depth-2 blue darken-4 white-text pulse"
          >
            <i class="material-icons right">phone_in_talk</i>
          </a>
        </h1>
        <h1
          class="grey-text text-darken-4"
          style="
            display: none;
            font-family: 'Lato', sans-serif;
            font-size: 40px;
            text-shadow: 15px 15px 15px #aaa;
          "
        >
          taxi en San Luis
        </h1>
      </div>
    </div>
  </div>

  <!--// LAPTOP-->
  <div class="hide-on-small-and-down">
    <div class="col l5 right-align hide-on-small" style="margin-top: 80px">
      <img
        class="responsive-img left-align"
        style="width: 90%; height: 90%"
        [defaultImage]="defaultImage"
        lazyLoad="../assets/images/norte_remis_1.svg"
      />
      <h1
        class="grey-text text-darken-4"
        style="
          display: none;
          font-family: 'Lato', sans-serif;
          font-size: 40px;
          text-shadow: 15px 15px 15px #aaa;
        "
      >
        taxi en San Luis
      </h1>
    </div>

    <div class="col l7 hide-on-small">
      <div class="slider right-align">
        <ul class="slides transparent right-align">
          <li>
            <img
              class="hide responsive-img"
              src="../assets/images/Whatsapp.png"
            />
            <div class="caption right-align">
              <h3 class="grey-text text-darken-4">Mayor Flota en San Luis</h3>
              <h5 class="light black-text text-lighten-3">
                Cobertura en toda la provincia y el país.
              </h5>
            </div>
          </li>
          <li>
            <div class="caption right-align">
              <h3 class="grey-text text-darken-4">Siempre Disponible</h3>
              <h5 class="light black-text text-lighten-3">
                Las 24hs, los 356 días de año.
              </h5>
            </div>
          </li>
          <li>
            <div class="caption right-align">
              <h3 class="grey-text text-darken-4">Extensa Trayectoria</h3>
              <h5 class="light black-text text-lighten-3">
                Más de 20 años en servicio.
              </h5>
            </div>
          </li>
          <li>
            <div class="caption right-align black-text">
              <h3 class="grey-text text-darken-4">Servicios Personalizados</h3>
              <h5 class="light black-text text-lighten-3">
                Viajes especiales y programados.
              </h5>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="col l12 hide-on-small" style="margin-top: 40px">
      <div class="col l12 center-align hide-on-small" style="padding-right: 5%">
        <h1
          class="grey-text text-darken-4"
          style="
            font-family: 'Lato', sans-serif;
            font-size: 88px;
            text-shadow: 20px 20px 20px #aaa;
          "
        >
          266-4245230
        </h1>
      </div>
    </div>
  </div>
</div>

<div class="parallax-container hide-on-small-and-down" style="height: 350px">
  <div class="parallax"><img src="../assets/images/sanluis1.jpg" /></div>
</div>

<!--  ..........................................................................................
                                          APP
............................................................................................  -->

<!--        SMARPHONE-->
<div class="container">
  <div id="apps" class="row white center-align scrollspy hide-on-med-and-up">
    <div class="col s12" style="padding: 40px; margin-top: 20px">
      <img class="responsive-img" src="../assets/images/norte_remis_1.svg" />
      <div class="section">
        <p>
          Ahora podes pedir tu taxi desde tu celular. Envialo a una dirección en
          particular o a tu ubicación actual.
        </p>
        <p>Descargá gratis la aplicación.</p>
      </div>
      <div class="section">
        <img
          class="responsive-img"
          style="width: 65%; height: 65%"
          src="../assets/images/googleplay_latam_store.png"
        />
      </div>
      <div class="section">
        <a
          href="https://play.google.com/store/apps/details?id=net.operadorvirtual.norteremis.clientes&pcampaignid=web_share"
          class="waves-effect waves-light btn-large blue darken-4 hoverable z-depth-1"
          ><i class="material-icons right">file_download</i
          ><span style="font-weight: bold">Descargar</span></a
        >
      </div>
    </div>
    <div class="col s12" style="margin-bottom: 60px">
      <video controls style="height: 550px; width: 100%">
        <source
          src="../assets/videos/norteremis_app_video.mp4"
          type="video/mp4"
        />
      </video>
    </div>
  </div>
</div>

<!--        LAPTOP-->
<div
  id="app"
  class="row white center-align scrollspy hide-on-small-and-down"
  style="height: 100%; margin-bottom: auto"
>
  <div
    class="col l5 offset-l1 grey-text text-darken-4"
    style="padding: 80px; margin-top: 40px"
  >
    <img class="responsive-img" src="../assets/images/norte_remis_1.svg" />
    <div class="section">
      <h6>
        Ahora podes pedir tu taxi desde tu celular. Envialo a una dirección en
        particular o a tu ubicación actual.
      </h6>
      <h6>Descargá gratis la aplicación.</h6>
    </div>
    <div class="section">
      <img
        class="responsive-img"
        style="width: 45%; height: 45%"
        src="../assets/images/googleplay_latam_store.png"
      />
    </div>
    <div class="section">
      <a
        href="https://play.google.com/store/apps/details?id=net.operadorvirtual.norteremis.clientes&pcampaignid=web_share"
        class="waves-effect waves-light btn-large blue darken-4 hoverable z-depth-1"
        ><i class="material-icons right">file_download</i
        ><span style="font-weight: bold">Descargar</span></a
      >
    </div>
  </div>

  <div class="col l5 grey-text text-darken-4" style="margin-top: 160px">
    <div class="row">
      <div class="col s12">
        <video controls style="height: 650px; width: 85%">
          <source
            src="../assets/videos/norteremis_app_video.mp4"
            type="video/mp4"
          />
        </video>
      </div>
    </div>
  </div>
</div>

<!--  ..........................................................................................
                                          WHATSAPP
     ............................................................................................  -->

<!--SMARPHONE-->
<div
  id="whatsapps"
  class="row white-text scrollspy hide-on-med-and-up"
  style="
    margin-bottom: auto;
    background-image: url(../assets/images/placeholder.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  "
  lazyLoad="../assets/images/ws_WhatsApp_Background_1920x1080.jpg"
>
  <div class="col s12 center-align" style="margin-top: 10%">
    <h5>Ahora podes usar WhatsApp para pedir tu remis.</h5>
    <h5>Ahorrá tiempo y envianos un mensaje.</h5>
  </div>
  <div class="col s12 center-align">
    <div style="padding-top: 15%">
      <img
        class="responsive-img"
        src="../assets/images/Whatsapp.png"
        style="height: 30%; width: 30%"
      />
    </div>
    <div>
      <h3 style="margin-top: 0px; margin-bottom: 40px">WhatsApp</h3>
    </div>
  </div>
  <div class="col s12 center-align" style="padding: 10%; padding-top: 0px">
    <img class="responsive-img" src="../assets/images/62738299527573.png" />
  </div>
  <div class="col s12 center-align">
    <h1 style="font-family: 'Lato', sans-serif; font-size: 40px">
      266-5003199
    </h1>
    <a
      href="https://wa.me/542665003199"
      class="waves-effect waves-light btn-large hoverable z-depth-2 green white-text"
    >
      <i class="material-icons right">message</i>Enviar ahora
    </a>
  </div>
  <div class="col s12 center-align" style="padding-bottom: 20%">
    <h1 style="font-family: 'Lato', sans-serif; font-size: 30px">
      266-5048888
    </h1>
    <a
      href="https://wa.me/542665048888"
      class="waves-effect waves-light btn-small hoverable z-depth-2 green white-text"
    >
      <i class="material-icons right">message</i>Enviar ahora
    </a>
  </div>
</div>

<!--        LAPTOP-->
<div
  id="whatsapp"
  class="row white-text scrollspy hide-on-small-and-down"
  style="
    margin-bottom: auto;
    height: 100%;
    background-image: url(../assets/images/placeholder.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  "
  lazyLoad="../assets/images/ws_WhatsApp_Background_1920x1080.jpg"
>
  <div class="col l12 center-align" style="margin-top: 3%">
    <h5>Ahora podes usar WhatsApp o Telegram para pedir tu remis.</h5>
    <h5>Ahorrá tiempo y envianos un mensaje.</h5>
  </div>
  <div class="col l6 right-align" style="padding: 50px">
    <img
      class="responsive-img"
      [defaultImage]="defaultImage"
      lazyLoad="../assets/images/62738299527573.png"
    />
  </div>
  <div class="col l4 left-align" style="padding: 10px">
    <div class="center-align">
      <img
        class="responsive-img"
        src="../assets/images/Whatsapp.png"
        style="height: 30%; width: 30%; margin-top: 5%"
      />
    </div>
    <h2 class="center-align" style="margin-top: 0%">Whatsapp</h2>
    <h1
      class="center-align"
      style="font-family: 'Lato', sans-serif; font-size: 70px"
    >
      266-5003199
    </h1>
    <div class="hide-on center-align">
      <div class="show-on-large hide-on-med-and-down">
        <a
          href="https://wa.me/542665003199"
          class="waves-effect waves-light btn-large hoverable z-depth-2 green white-text"
        >
          <i class="material-icons right">message</i>Pedir ahora
        </a>
      </div>
    </div>
    <h1
      class="center-align"
      style="font-family: 'Lato', sans-serif; font-size: 50px"
    >
      266-5048888
    </h1>
    <div class="hide-on center-align">
      <div class="show-on-large hide-on-med-and-down">
        <a
          href="https://wa.me/542665048888"
          class="waves-effect waves-light btn-large hoverable z-depth-2 green white-text"
        >
          <i class="material-icons right">message</i>Pedir ahora
        </a>
      </div>
    </div>
  </div>
</div>

<div class="parallax-container hide-on-small-and-down" style="height: 350px">
  <div class="parallax"><img src="../assets/images/sanluis3.jpg" /></div>
</div>

<!--  ..........................................................................................
                                          NOSOTROS
     ............................................................................................  -->

<!--        LAPTOP-->
<div
  id="nosotrosl"
  class="row white center-align scrollspy hide-on-small-and-down"
  style="height: 100%; margin-bottom: auto"
>
  <div class="col l12" style="margin-top: 5%">
    <h3 class="center-align grey-text text-darken-4">Nosotros por dentro</h3>
  </div>
  <div class="col l12">
    <h6 class="center-align grey-text text-darken-4">
      Norte Remis presenta la flota de taxis y remises más grande de San Luís
      con más de
      <span style="font-weight: bold">200 vehículos </span>disponibles.
      <span style="font-weight: bold">20 años de experiencia </span> avalan
      nuestros servicios.
    </h6>
    <h6 class="center-align grey-text text-darken-4">
      Contamos además con un
      <span style="font-weight: bold">sistema computarizado</span> para la
      administración y seguimiento de cada viaje.
    </h6>
    <div class="section"></div>
  </div>
  <div class="col l5 offset-l1" style="margin-top: 5%">
    <div class="card hoverable">
      <div class="card-image z-depth-4">
        <div class="slider">
          <ul class="slides">
            <li>
              <img
                [defaultImage]="defaultImage"
                lazyLoad="../assets/images/flota1.jpeg"
              />
            </li>
            <li>
              <img
                [defaultImage]="defaultImage"
                lazyLoad="../assets/images/flota2.jpeg"
              />
            </li>
            <li>
              <img
                [defaultImage]="defaultImage"
                lazyLoad="../assets/images/flota3.jpeg"
              />
            </li>
            <li>
              <img
                [defaultImage]="defaultImage"
                lazyLoad="../assets/images/flota4.jpeg"
              />
            </li>
            <li>
              <img
                [defaultImage]="defaultImage"
                lazyLoad="../assets/images/flota5.jpeg"
              />
            </li>
            <li>
              <img
                [defaultImage]="defaultImage"
                lazyLoad="../assets/images/flota6.jpeg"
              />
            </li>
            <li>
              <img
                [defaultImage]="defaultImage"
                lazyLoad="../assets/images/flota7.jpeg"
              />
            </li>
            <li>
              <img
                [defaultImage]="defaultImage"
                lazyLoad="../assets/images/flota8.jpeg"
              />
            </li>
            <li>
              <img
                [defaultImage]="defaultImage"
                lazyLoad="../assets/images/flota9.jpeg"
              />
            </li>
            <li>
              <img
                [defaultImage]="defaultImage"
                lazyLoad="../assets/images/flota10.jpeg"
              />
            </li>
            <li>
              <img
                [defaultImage]="defaultImage"
                lazyLoad="../assets/images/flota11.jpeg"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="col l5" style="margin-top: 5%">
    <div class="card hoverable">
      <div class="card-image z-depth-4">
        <div class="slider">
          <ul class="slides">
            <li>
              <img
                [defaultImage]="defaultImage"
                lazyLoad="../assets/images/sistema1.jpeg"
              />
            </li>
            <li>
              <img
                [defaultImage]="defaultImage"
                lazyLoad="../assets/images/sistema2.jpeg"
              />
            </li>
            <li>
              <img
                [defaultImage]="defaultImage"
                lazyLoad="../assets/images/sistema3.jpeg"
              />
            </li>
            <li>
              <img
                [defaultImage]="defaultImage"
                lazyLoad="../assets/images/sistema4.jpeg"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<!--        SMARTPHONE-->
<div id="nosotross" class="row white center-align scrollspy hide-on-med-and-up">
  <div class="col s12">
    <h3 class="center-align grey-text text-darken-4">Nosotros por dentro</h3>
  </div>
  <div class="col s12">
    <h6 class="center-align grey-text text-darken-4">
      Norte Remis presenta la flota de taxis y remises más grande de San Luís
      con más de
      <span style="font-weight: bold">200 vehículos </span>disponibles.
      <span style="font-weight: bold">20 años de experiencia </span> avalan
      nuestros servicios.
    </h6>
    <h6 class="center-align grey-text text-darken-4">
      Contamos además con un
      <span style="font-weight: bold">sistema computarizado</span> para la
      administración y seguimiento de cada viaje.
    </h6>
    <div class="section"></div>
  </div>
  <div class="col s12">
    <div class="card hoverable">
      <div class="card-image z-depth-4">
        <div class="slider">
          <ul class="slides">
            <li>
              <img
                [defaultImage]="defaultImage"
                lazyLoad="../assets/images/flota1.jpeg"
              />
            </li>
            <li>
              <img
                [defaultImage]="defaultImage"
                lazyLoad="../assets/images/flota2.jpeg"
              />
            </li>
            <li>
              <img
                [defaultImage]="defaultImage"
                lazyLoad="../assets/images/flota3.jpeg"
              />
            </li>
            <li>
              <img
                [defaultImage]="defaultImage"
                lazyLoad="../assets/images/flota4.jpeg"
              />
            </li>
            <li>
              <img
                [defaultImage]="defaultImage"
                lazyLoad="../assets/images/flota5.jpeg"
              />
            </li>
            <li>
              <img
                [defaultImage]="defaultImage"
                lazyLoad="../assets/images/flota6.jpeg"
              />
            </li>
            <li>
              <img
                [defaultImage]="defaultImage"
                lazyLoad="../assets/images/flota7.jpeg"
              />
            </li>
            <li>
              <img
                [defaultImage]="defaultImage"
                lazyLoad="../assets/images/flota8.jpeg"
              />
            </li>
            <li>
              <img
                [defaultImage]="defaultImage"
                lazyLoad="../assets/images/flota9.jpeg"
              />
            </li>
            <li>
              <img
                [defaultImage]="defaultImage"
                lazyLoad="../assets/images/flota10.jpeg"
              />
            </li>
            <li>
              <img
                [defaultImage]="defaultImage"
                lazyLoad="../assets/images/flota11.jpeg"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="col s12">
    <div class="card hoverable">
      <div class="card-image z-depth-4">
        <div class="slider">
          <ul class="slides">
            <li>
              <img
                [defaultImage]="defaultImage"
                lazyLoad="../assets/images/sistema1.jpeg"
              />
            </li>
            <li>
              <img
                [defaultImage]="defaultImage"
                lazyLoad="../assets/images/sistema2.jpeg"
              />
            </li>
            <li>
              <img
                [defaultImage]="defaultImage"
                lazyLoad="../assets/images/sistema3.jpeg"
              />
            </li>
            <li>
              <img
                [defaultImage]="defaultImage"
                lazyLoad="../assets/images/sistema4.jpeg"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<!--  ..........................................................................................
                                       FOOTER
  ............................................................................................  -->

<!--        LAPTOP-->
<div
  class="row contenedor-div hide-on-small-and-down"
  style="
    margin-bottom: auto;
    height: 100%;
    background-image: url(../assets/images/placeholder.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  "
  lazyLoad="../assets/images/flota9.jpeg"
>
  <div class="mi-imagen-abajo-derecha">
    <footer class="page-footer" style="background-color: #151515">
      <div class="container">
        <div class="row">
          <div class="col l6 s12">
            <h5 class="white-text">Norte Remis</h5>
            <p class="grey-text text-lighten-4">
              Norte Remis es una empresa de San Luis dedicada al servicio de
              translado de pasajeros en general.
            </p>
            <p>
              <span style="font-weight: bold">Palabras clave:</span>
              <span
                style="border-radius: 2%"
                class="badge grey darken-3 white-text"
                >taxi en San Luis</span
              >
              <span
                style="border-radius: 2%"
                class="badge grey darken-3 white-text"
                >remis en San Luis</span
              >
              <span
                style="border-radius: 2%"
                class="badge grey darken-3 white-text"
                >Norte Remis en San Luis</span
              >
            </p>
          </div>
          <div class="col l4 offset-l2 s12">
            <div class="row">
              <div class="col s12 left-align">
                <div class="col s12 left-align">
                  <h5 class="white-text">Contacto</h5>
                </div>
              </div>
              <div class="col s12 left-align">
                <div class="col s1 left-align">
                  <i class="material-icons white-text left-align">email</i>
                </div>
                <div class="col s11 left-align">
                  <a
                    class="grey-text text-lighten-3"
                    href="mailto:r-norte-sl@hotmail.com"
                    >r-norte-sl@hotmail.com</a
                  >
                </div>
              </div>
              <div class="col s12 left-align">
                <div class="col s1 left-align">
                  <i class="material-icons white-text left-align">phone</i>
                </div>
                <div class="col s11 left-align">
                  <a class="grey-text text-lighten-3">266-4245230</a>
                </div>
              </div>
              <div class="col s12 left-align">
                <div class="col s1 left-align">
                  <i class="material-icons white-text left-align"
                    >desktop_windows</i
                  >
                </div>
                <div class="col s11 left-align">
                  <a class="grey-text text-lighten-3"
                    ><span> </span>www.norteremis.com</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-copyright black">
        <div class="container">
          <div class="center-align">Todos los derechos reservados © 2024</div>
        </div>
      </div>
    </footer>
  </div>
</div>

<!--        SMARPHONE-->
<footer
  class="page-footer hide-on-med-and-up"
  style="background-color: #151515"
>
  <div class="container">
    <div class="row">
      <div class="col l6 s12">
        <div class="row">
          <div class="col s12 left-align">
            <div class="col s12 left-align">
              <h5 class="white-text">Norte Remis</h5>
              <p class="grey-text text-lighten-4">
                Norte Remis es una empresa de San Luis dedicada al servicio de
                translado de pasajeros en general.
              </p>
            </div>
            <div class="col s12 left-align">
              <p style="font-weight: bold">Palabras clave:</p>
              <div class="col s12">
                <div class="wrapperClass">
                  <span class="badgeClass" style="margin: 5px"
                    >Norte Remis en San Luis</span
                  >
                </div>
                <div class="wrapperClass">
                  <span class="badgeClass" style="margin: 5px"
                    >remis en San Luis</span
                  >
                </div>
                <div class="wrapperClass">
                  <span class="badgeClass" style="margin: 5px"
                    >taxi en San Luis</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col l4 offset-l2 s12">
        <div class="row">
          <div class="col s12 left-align">
            <div class="col s12 left-align">
              <h5 class="white-text">Contacto</h5>
            </div>
          </div>
          <div class="col s12 left-align">
            <div class="col s1 left-align">
              <i class="material-icons white-text left-align">email</i>
            </div>
            <div class="col s11 left-align">
              <a
                class="grey-text text-lighten-3"
                href="mailto:r-norte-sl@hotmail.com"
                >r-norte-sl@hotmail.com</a
              >
            </div>
          </div>
          <div class="col s12 left-align">
            <div class="col s1 left-align">
              <i class="material-icons white-text left-align">phone</i>
            </div>
            <div class="col s11 left-align">
              <a class="grey-text text-lighten-3">266-4245230</a>
            </div>
          </div>
          <div class="col s12 left-align">
            <div class="col s1 left-align">
              <i class="material-icons white-text left-align"
                >desktop_windows</i
              >
            </div>
            <div class="col s11 left-align">
              <a class="grey-text text-lighten-3"
                ><span> </span>www.norteremis.com</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-copyright black">
    <div class="container">
      <div class="center-align">Todos los derechos reservados © 2024</div>
    </div>
  </div>
</footer>
